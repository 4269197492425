@import url("assets/css/fonts.css");
@import url("reparticoes-css/primereact/picklist.css");
@import url("reparticoes-css/primereact/multiselect.css");
@import url("reparticoes-css/primereact/tab-view.css");
@import url("reparticoes-css/sizing/sizing.css");
@import url("reparticoes-css/animations.css");
@import url("reparticoes-css/background-colors.css");
@import url("reparticoes-css/bases-globais.css");
@import url("reparticoes-css/borders.css");
@import url("reparticoes-css/box-shadows.css");
@import url("reparticoes-css/buttons.css");
@import url("reparticoes-css/cards.css");
@import url("reparticoes-css/cliploaders.css");
@import url("reparticoes-css/containers.css");
@import url("reparticoes-css/controle-diario.css");
@import url("reparticoes-css/curriculo.css");
@import url("reparticoes-css/cursors.css");
@import url("reparticoes-css/dropdown.css");
@import url("reparticoes-css/filters.css");
@import url("reparticoes-css/flexes.css");
@import url("reparticoes-css/folha-arquivo.css");
@import url("reparticoes-css/fonts.css");
@import url("reparticoes-css/high-lights.css");
@import url("reparticoes-css/icons.css");
@import url("reparticoes-css/inputs.css");
@import url("reparticoes-css/margins.css");
@import url("reparticoes-css/medias.css");
@import url("reparticoes-css/modal.css");
@import url("reparticoes-css/navbar.css");
@import url("reparticoes-css/ns-styles.css");
@import url("reparticoes-css/overflows.css");
@import url("reparticoes-css/paddings.css");
@import url("reparticoes-css/scroll.css");
@import url("reparticoes-css/tables.css");
@import url("reparticoes-css/themes.css");
@import url("reparticoes-css/tooltip.css");
@import url("reparticoes-css/titles.css");
@import url("reparticoes-css/webcam-style.css");
.some {
  display: none !important;
  cursor: none !important;
}

.perfil-image {
  object-fit: cover;
  object-position: center;
  width: 140px !important;
  height: 140px !important;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  border: 1px solid grey;
}

strong {
  font-weight: 1000 !important;
}

.scale-0-9 {
  transform: scale(0.9);
}

@media (max-width: 800px) {
  .sm-some {
    display: none !important;
  }
}
.show {
  border-bottom-left-radius: 0px !important;
}

