.btn-executado {
    padding: 10px;
    background-color: aquamarine;
    border-radius: 15px;
    width: 100%;
    margin: 0;
}

.table-check-in-linha td {
    border-radius: 15px;
}

.table-check-in-linha td .hora {
    width: auto;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table-check-in-linha input:not([type="checkbox"]) {
    border: none;
    width: 100%;
    height: 100%;
    padding-left: 15px;
}

/* Customize the label (the check-label) */
.table-check-in-linha .check-label {
    margin-top: -4px;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Hide the browser's default checkbox */
.table-check-in-linha .check-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.table-check-in-linha .checkmark {
    height: 25px;
    width: 25px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.table-check-in-linha .check-label:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.table-check-in-linha .check-label input:checked~.checkmark {
    background-color: var(--roxo);
}

/* Create the checkmark/indicator (hidden when not checked) */
.table-check-in-linha .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.table-check-in-linha .check-label input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.table-check-in-linha .check-label .checkmark:after {
    top: 30%;
    left: 47%;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

}

.table-check-in-linha .react-select__control {
    border-radius: 15px !important;
    border: none !important;
}

.table-check-in-linha .react-select__control--menu-is-open {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.table-check-in-linha .react-select__menu {
    margin-top: 1px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    border: none !important;
    padding-bottom: 0px;
}

.table-check-in-linha .react-select__menu div:last-child {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    margin-bottom: 0px;
}
.table-check-in-linha .no-style {
    all: unset !important;
}
.table-check-in-linha .select-pendente .react-select__control{
    background-color: #ffd6d6;
}
.table-check-in-linha .select-pendente{
    background-color: #ffd6d6;
    border-radius: 15px;
}
.table-check-in-linha .select-executado .react-select__control{
    background-color: aquamarine;
}
.table-check-in-linha .select-executado{
    background-color: aquamarine;
    border-radius: 15px;
}