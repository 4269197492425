.carregando{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    cursor: progress;
    background-color: rgba(0,0,0,0.2);

    justify-content: center;
    align-items: center;
    text-align: center;

    z-index: 800;

    overflow: hidden;
}
.carregando div{
    position: fixed;
    top: 50% !important;
    left: 50% !important;
}