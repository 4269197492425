.btlr-0 {
    border-top-left-radius: 0px !important;
}

.bblr-0 {
    border-bottom-left-radius: 0px !important;
}

.td-p-ok {
    background-color: #35d058 !important;
}

.td-p-pendente {
    background-color: rgb(249, 65, 129) !important;
}

.td-p-pendente:hover {
    background-color: rgb(255, 109, 160);
    box-shadow: 15px 15px 15px 15px rgb(255, 109, 160);
}

.table-controle-diario {
    max-width: 100vw !important;
    border-collapse: collapse;
    display: flex;
    flex-direction: column;
}

.table-controle-diario thead {
    width: 100% !important;
    max-height: 10px !important;
    display: block;
}

.table-controle-diario th {
    color: #160136;
    font-size: 10.5pt;
    text-align: center;
    background-color: #ececec;
    padding: 5px;

    border-radius: 10px;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.table-controle-diario tbody {
    flex-grow: 1;
    width: 100% !important;
    height: calc(100vh - 356px);
    display: block;
    overflow-y: scroll;
    margin-top: 42.5px;
    border-collapse: separate;
    border-spacing: 0px 1px;
    scrollbar-width: none;
}

.table-controle-diario tbody::-webkit-scrollbar {
    display: none;
}

.table-controle-diario td {
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    padding: 0px;
    margin: 0px;
}

.table-controle-diario td p {
    margin: 0px;
    padding: 0px;
}

.table-controle-diario .td-hora {
    background-color: #DFCDF8;
    color: #9586AC;
    padding: 5px;
    box-shadow: 0 0 2px 1px silver;
    border-radius: 10px;
    border-color: #F5F7FA;
    border-style: solid;
    font-weight: bolder;
    text-align: center;
}

.table-controle-diario tfoot {
    display: table;
    height: 40px;
}

.table-controle-diario tfoot th {
    height: 40px;
}

.tabela-alteracao {
    width: 100% !important;
    height: auto !important;
    margin: 0px !important;
    box-shadow: 0 0 3px 0px silver !important;
    display: grid;
    text-align: center;
    list-style: none !important;
    padding: 0px !important;
}

.tabela-alteracao li {
    border: 1px solid #000 !important;
    border-radius: 10px !important;
    align-items: center !important;
    font-weight: 400 !important;
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    margin: auto !important;
}

.td-content {
    border-radius: 10px;
    background-color: #fff;
    vertical-align: middle;
    text-align: center;
    max-height: 80px !important;
}

.td-content .div-nome-terapeuta {
    display: flex;
    flex-wrap: nowrap;
    height: 100% !important;
    align-items: center;
    flex-direction: row;
}

.td-content .div-nome-terapeuta .nome-terapeuta {
    display: flex;
    padding: 0px;
    align-items: center;
}

.td-content .div-nome-terapeuta .hora-diferente {
    background-color: rgb(255, 119, 119);
    color: #000;
    border-radius: 0 10px 10px 0;

    padding: 0px;
    margin: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0pt;

    width: 10px !important;
    min-width: 10px !important;
    max-width: 10px !important;
    height: 100% !important;
}

.td-content .div-nome-terapeuta .hora-diferente:hover {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
    font-size: 10pt;
}

.td-content .react-select__input-container {
    text-align: left;
}

.table-controle-diario td button {
    background-color: #7340BD;
    color: #FFFFFF;
    border: none;
    padding: 7px 30px;
    border-radius: 10px;
    margin-left: 10;
}

/* HORA */
.table-controle-diario .td-hora,
.cd-col-0 {
    max-width: calc(5vw - 50px) !important;
    min-width: calc(5vw - 50px) !important;
}

/* TERAPEUTA */
.table-controle-diario .cd-col-1,
.cd-col-1 {
    max-width: 14vw !important;
    min-width: 14vw !important;
}

/* TERAPIA */
.table-controle-diario .cd-col-2,
.cd-col-2 {
    max-width: calc(14vw - 22px) !important;
    min-width: calc(14vw - 22px) !important;
}

/* ALTERACAO */
.table-controle-diario .cd-col-3,
.cd-col-3 {
    max-width: 4vw !important;
    min-width: 4vw !important;
}

/* PACIENTE */
.table-controle-diario .cd-col-4,
.cd-col-4 {
    max-width: 8VW !important;
    min-width: 8VW !important;
}

/* SALA ESTACAO */
.table-controle-diario .cd-col-5,
.cd-col-5 {
    max-width: 5vw !important;
    min-width: 5vw !important;
}

/* TIPO E MODALIDADE */
.table-controle-diario .cd-col-6,
.cd-col-6 {
    max-width: 9vw !important;
    min-width: 9vw !important;
}

/* STATUS TERAPEUTA */
.table-controle-diario .cd-col-7,
.cd-col-7 {
    max-width: 10vw !important;
    min-width: 10vw !important;
}

/* STATUS RECEPCAO */
.table-controle-diario .cd-col-8,
.cd-col-8 {
    max-width: 15vw !important;
    min-width: 15vw !important;
}

/* LIBERADO */
.table-controle-diario .cd-col-9,
.cd-col-9 {
    max-width: 6vw !important;
    min-width: 6vw !important;
}

/* -- */
.table-controle-diario .cd-col-10,
.cd-col-10 {
    max-width: 6vw !important;
    min-width: 6vw !important;
}

/* COMENTARIO */
.table-controle-diario .cd-col-11,
.cd-col-11 {
    max-width: 3vw !important;
    min-width: 3vw !important;
}

/* P */
.table-controle-diario .cd-col-12,
.cd-col-12 {
    max-width: 1vw !important;
    min-width: 1vw !important;
}

.div-controlediario-cabecalho {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 12px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-top: 0px;
    align-items: center;
}

.div-seletor-style {
    width: 16%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.div-seletor-style-solo {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.div-buttons-style {
    width: 42%;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.table-controle-diario .p-executado {
    background-color: aquamarine !important;
}

.table-controle-diario .p-pendende {
    background-color: #ff9f9f !important;
}