.table-default{
    width: 100% !important;
    max-height: 30vw !important;

    margin: auto;
}
.table-default thead{
    width: 100%;
    max-height: 10px !important;
    min-height: 10px !important;
}
.table-default tbody{
    width: 100% !important;
    max-height: 30vw !important;

    overflow-y: scroll;
}
.table-default td{
    padding: 0.8%;
}
.table-default th{
    color: #4906AB;
    font-weight: 600;
}
.table-default td button{
    background-color: #7340BD;
    color: #FFFFFF;
    border: none;
    padding: 7px 30px;
    border-radius: 10px;
    margin-left: 10;
}