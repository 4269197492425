:root {
    /* Variáveis para largura e altura base */
    --base-width: 90px;
    --base-height: 70px;
    --base-width-hora: 90px;
    --base-height-hora: 70px;
    /* Variáveis de estado */
    --is-full-screen: false;
    --espera-mode: false;
    /* Variável para diferença base */
    --difference-base-x: 19px;
    --difference-base-y: 345px;
    /* Cores definidas diretamente */
    --bg-th-hora: #DFCDF8;
    /* Cor padrão */
    --bg-th-salas: #00B19D;
    /* Cor padrão */
    --bg-filtro: rgb(149, 134, 172);
    /* Calculado */
    --table-max-width: calc(100vw - var(--base-width) - var(--difference-base-x));
}

.table-agenda-terapeuta {
    max-width: 100vw;
    scroll-behavior: smooth;
    table-layout: auto;
}

.table-agenda-terapeuta .thead {
    display: flex;
    flex-direction: column;
    max-width: calc(var(--table-max-width) - 15px);
    overflow-x: hidden;
}

.table-agenda-terapeuta .thead .tr,
.table-agenda-terapeuta .thead .trE,
.table-agenda-terapeuta .thead .trS,
.table-agenda-terapeuta .thead .trR {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 32px;
}

.thead .trR {
    height: 55px;
}

.table-agenda-terapeuta .tr0 {
    background-color: #EAEAEA;
    color: #9586AC;
    box-shadow: 0 0 3px 1px silver;
    padding: 0;
    border-radius: 0;
    border-color: #F5F7FA;
    border-style: solid;
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.table-agenda-terapeuta .tr1 {
    background-color: #EAEAEA;
    color: #9586AC;
    box-shadow: 0 0 3px 1px silver;
    text-align: center;
    max-width: var(--base-width-hora);
    min-width: var(--base-width-hora);
    border-radius: 0;
    border-color: #F5F7FA;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-agenda-terapeuta .tr2,
.tr2T {
    background-color: var(--bg-th-salas);
    color: #fff;
    text-align: center;
    max-width: var(--base-width);
    min-width: var(--base-width);
    height: 32px;
    min-height: 32px;
    box-shadow: 0 0 3px 1px silver;
    border-radius: 0;
    border-color: #F5F7FA;
    border-style: solid;
    align-content: center;
    align-items: center;
}

.table-agenda-terapeuta .tr3 {
    background-color: #E6C833;
    color: #fff;
    text-align: center;
    max-width: var(--base-width);
    min-width: var(--base-width);
    border-radius: 0;
    border-color: #F5F7FA;
    border-style: solid;
    align-content: center;
    align-items: center;
}

.table-agenda-terapeuta .tbody0 {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--difference-base-y));
    max-width: var(--base-width);
    overflow: hidden;
}

.table-agenda-terapeuta .tbody0 tr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: var(--base-height);
    width: 100%;
}

.tbody-agenda-semanal {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--difference-base-y));
    max-width: var(--table-max-width);
    overflow: auto;
    scrollbar-gutter: stable;
}

.tbody-agenda-semanal::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: content-box;
}

.tbody::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.tbody-agenda-semanal tr {
    display: flex;
    flex-direction: row;
    height: var(--base-height);
}

.tbody-agenda-semanal div {
    max-width: 100vw;
}
.tbody-agenda-semanal .tr-vazio {
    background-color: #fff;
    padding: 0;
    width: var(--base-width);
    height: var(--base-height);
    box-shadow: 0 0 3px 1px silver;
    border-radius: 10px;
    border-color: #F5F7FA;
    border-style: solid;
    vertical-align: top;
}

.tbody-agenda-semanal .tr-vazio:hover {
    background-color: #f0f0f0;
}

.tbody-agenda-semanal .tr-hora {
    background-color: var(--bg-th-hora);
    color: #9586AC;
    padding: 5px;
    box-shadow: 0 0 2px 1px silver;
    max-width: var(--base-width-hora);
    min-width: var(--base-width-hora);
    max-height: var(--base-height-hora);
    min-height: var(--base-height-hora);
    border-radius: 0;
    border-color: #F5F7FA;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tbody-agenda-semanal .tarde {
    background-color: #e0d1ad;
}

.tbody-agenda-semanal .hora-diferente {
    font-size: 0;
    background-color: rgb(255, 102, 102);
    height: 10px;
    border-radius: 0 0 10px 10px;
    border: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
}

.tbody-agenda-semanal .linha {
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
    color: #fff;
}

.tbody-agenda-semanal .quadrado120 {
    height: 80px;
    width: 120px;
}

.tbody-agenda-semanal .border1 {
    border: #000000 solid 1px;
}

.tbody-agenda-semanal .wh15px {
    width: 15px;
    height: 15px;
}

.tbody-agenda-semanal .w250px {
    width: 250px;
}

.tbody-agenda-semanal .tr-ocupado {
    color: #fff;
    padding: 0;
    width: var(--base-width);
    height: var(--base-height);
    border-radius: 10px;
    text-align: center;
}

.tbody-agenda-semanal .hovered-linha {
    background-color: rgb(240, 230, 255) !important;
}

.tbody-agenda-semanal .hovered-coluna {
    background-color: #8afff1 !important;
    color: black !important;
}