@font-face {
    font-family: 'Nunito';
    src: url('/src/assets/fonts/Nunito-VariableFont_wght.ttf') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/src/assets/fonts/Nunito-VariableFont_wght.ttf') format('woff');
    font-weight: 700;
    font-style: normal;
}