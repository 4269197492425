.ns-suggestions-list {
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    z-index: 1000;
    list-style: none;
    padding: 0;

    .ns-suggestion-item {
        padding: 8px 12px;
        cursor: pointer;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}