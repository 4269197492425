:root {
    /* Variáveis para largura e altura base */
    --base-width: 90px;
    --base-height: 70px;
    --base-width-hora: 90px;
    --base-height-hora: 70px;

    /* Variáveis de estado */
    --is-full-screen: false;
    --espera-mode: false;

    /* Variável para diferença base */
    --difference-base-x: 19px;
    --difference-base-y: 345px;

    /* Cores definidas diretamente */
    --bg-th-hora: #DFCDF8;
    /* Cor padrão */
    --bg-th-salas: #00B19D;
    /* Cor padrão */
    --bg-filtro: rgb(149, 134, 172);
    /* Cor padrão */

    /* Calculado */
    --table-max-width: calc(100vw - var(--base-width) - var(--difference-base-x));
}

/* Estilos base */
.tamanho-padrao-agenda {
    width: var(--base-width);
    height: var(--base-height);
}

.data-spam {
    color: #000000;
    font-weight: bold;
}

.botao-legenda {
    background-color: #7441BF;
    color: #FFFFFF;
    padding: 2px 32px;
    border-radius: 6px;
    margin: 10px 0;
    border: 0;
}

.table-agenda {
    max-width: 100vw;
    scroll-behavior: smooth;
    table-layout: auto;

    .thead {
        display: flex;
        flex-direction: column;

        tr {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            height: 32px;
        }
    }

    .tr0 {
        background-color: #EAEAEA;
        color: #9586AC;
        box-shadow: 0 0 3px 1px silver;
        padding: 0;
        border-radius: 0;
        border-color: #F5F7FA;
        border-style: solid;
        display: inline-flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }

    .tr1 {
        background-color: #EAEAEA;
        color: #9586AC;
        box-shadow: 0 0 3px 1px silver;
        text-align: center;
        width: var(--base-width-hora);
        border-radius: 0;
        border-color: #F5F7FA;
        border-style: solid;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selecionado {
            background-color: #9586AC;
            color: #EAEAEA;
        }
    }

    .tr2 {
        background-color: var(--bg-th-salas);
        color: #fff;
        text-align: center;
        width: var(--base-width);
        height: 32px;
        min-height: 32px;
        box-shadow: 0 0 3px 1px silver;
        border-radius: 0;
        border-color: #F5F7FA;
        border-style: solid;
        align-content: center;
        align-items: center;
    }

    .tr3 {
        background-color: #E6C833;
        color: #fff;
        text-align: center;
        width: var(--base-width);
        border-radius: 0;
        border-color: #F5F7FA;
        border-style: solid;
        align-content: center;
        align-items: center;
    }

    .tbody {
        display: flex;
        flex-direction: column;
        overflow: auto;

        .table-detaild-agenda {
            padding: 0px;
            height: 100%;
            display: grid;

            span {
                padding-left: 8px;
                border-top: 1px solid rgba($color: #000000, $alpha: 0.3);
                display: flex;
                align-items: center;

                &:first-child {
                    border: none !important;
                }
            }

        }
    }

    .tbody tr {
        display: flex;
        flex-direction: row;
    }

    .tr-hora {
        background-color: var(--bg-th-hora);
        color: #9586AC;
        padding: 5px;
        box-shadow: 0 0 2px 1px silver;
        width: var(--base-width-hora);
        height: var(--base-height-hora);
        border-radius: 0;
        border-color: #F5F7FA;
        border-style: solid;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tarde {
        background-color: #e0d1ad;
    }

    .tr-vazio {
        background-color: #fff;
        padding: 0;
        width: var(--base-width);
        height: var(--base-height);
        box-shadow: 0 0 3px 1px silver;
        border-radius: 10px;
        border-color: #F5F7FA;
        border-style: solid;
        border-width: 1px;
        vertical-align: top;
        color: white;
    }

    .tr-vazio:hover {
        background-color: #f0f0f0;
    }

    .tr-ocupado {
        color: #fff;
        padding: 0;
        width: var(--base-width);
        height: var(--base-height);
        border-radius: 10px;
        text-align: center;
    }

    .tr-reserva {
        border-color: #ff0000;
        border-width: 1px;
        border-style: solid;
        font-size: 12pt;
        padding: 0;
        color: #fff;
        width: var(--base-width);
        height: var(--base-height);
        border-radius: 10px;
        text-align: center;
        box-shadow: 5px 5px 15px grey;
    }

    .hora-diferente {
        font-size: 0;
        background-color: rgb(255, 102, 102);
        height: 10px;
        border-radius: 0 0 10px 10px;
        border: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
    }
}


.linha {
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
    color: #fff;
}

.btn-fechar {
    background-color: transparent;
    color: #f00;
    border: none;
    font-size: 10pt;
    font-weight: bold;
}

.div-titulo {
    width: calc(var(--base-width) * 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.svg-div {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    height: 10px;
    width: 100%;
}

.pos-div-icone {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hovered-linha {
    background-color: rgb(240, 230, 255) !important;
}

.hovered-coluna {
    background-color: #8afff1 !important;
    color: black !important;
}