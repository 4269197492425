:root {
    --notification_size_w: 300px;
    --notification_size_h: 200px;
    --roxo: #7441BF;
    --roxo_escuro: #391f60;
}

.ns-div-notificacoes {
    background-color: #fff;
    border-radius: 4%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.4);
    width: var(--notification_size_w);
    height: var(--notification_size_h);
    display: flex;
    flex-direction: column;
    top: 55px;
    right: 70px;
    margin-left: calc(-280px);
    color: var(--roxo_escuro);
}
.ns-bell-button {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    text-align: center;
    margin: auto;
    border: 1px solid #7441BF;
}

.ns-div-notificacoes ul {
    overflow-y: auto;
    /* Ajusta o comportamento de scroll */
    width: 100%;
    max-height: 140px;
    /* Torna a altura máxima flexível */
    margin: 0;
    padding: 10px;
    border-top: 1px solid var(--roxo_escuro);
    font-size: 12pt;
}

.ns-div-notificacoes li {
    color: var(--roxo_escuro);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
    margin-top: 3px;
}

.ns-div-notificacoes li .color-red {
    border-left: solid rgb(139, 0, 0) 2.5px;
    font-weight: bold;
}

.ns-div-notificacoes li div:first-child {
    padding-left: 4px;
    width: 78%;
}

.ns-div-notificacoes li div:first-child:hover,
.ns-div-notificacoes li div:first-child:focus {
    color: var(--roxo);
    font-size: 14pt;
    cursor: pointer;
}

.ns-div-notificacoes li div:last-child {
    width: 22%;
    text-align: right;
}

.ns-div-notificacoes li div:last-child .pop:hover,
.ns-div-notificacoes li div:last-child .pop:focus {
    color: var(--roxo);
    font-size: 14pt;
    cursor: pointer;
}

.ns-hover-bell {
    transition: 0.5s;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.ns-hover-bell.ns-hover-bell-manual {
    color: black;
    border: 4px solid #00728C;
}

.notificacaoPendente p {
    position: fixed;
    border-radius: 100px;
    background-color: red;
    color: white;
    font-size: 12px;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-left: 16px;
    margin-top: -5px;
}

.notificacaoPendente .back-azul {
    background-color: blueviolet;
}

.pop-light-blue:hover, .pop-light-blue:focus {
    color: #41bfbf;
    cursor: pointer;
}

/* Media query para ajustar responsividade */
@media (max-width: 768px) {
    .ns-div-notificacoes {
        width: 100%;
        height: auto;
        top: 45px;
        right: 0;
        margin-left: 0;
    }
}