.calendar {
  width: 400px;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  border: #ccc 1px solid;
  padding: 10px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.calendar-header h2 {
  margin: 0;
  font-size: 20pt;
}

.calendar-header button {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
}

.calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-weekday, .calendar-day {
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-day{
  cursor: pointer;
}
.calendar-day p{
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-day.marked p{
  border-bottom: red 1px solid;
}

.calendar-day.empty {
  background-color: #f9f9f9;
}

.calendar-day.disabled {
  color: grey;
  cursor: not-allowed;
}

.calendar-day.active {
  background-color: #fffae3;
  color: #000;
  border: #ffed9c 1px solid;
}